import { Button } from '@cimpress/react-components';
import React, { useState } from 'react';
import './deprecationBanner.scss';

const DeprecationBanner = (): JSX.Element => {
    const [isBannerVisible, setIsBannerVisible] = useState(true);

    return isBannerVisible ? (
        <div className="deprecation-banner">
            <div className="image-container">
                <img src="/depreacation_image.svg" alt="" />
            </div>
            <div>
                <h2>
                    <strong>
                        Templates are now in Content Asset Manager
                    </strong>
                </h2>
                <p>
                    All the templates have been migrated to Content Asset Manager.
                    <br />
                    Now all the assets are in one single place
                </p>
                <Button variant="primary" size="lg" href="https://cam.contentauthoring.cimpress.io/">
                    Open Content Asset Manager
                </Button>
                <Button href="https://cimpress-support.atlassian.net/wiki/spaces/Content/pages/16831808054/Why+We+Are+Moving+Templates+into+CAM" target="_blank" variant="link">
                    <span>Learn more on confluence</span>
                </Button>
            </div>
            <div className="close-button-container">
                <Button size="lg" onClick={(): void => setIsBannerVisible(false)}>
                    <i className="fa fa-times" />
                </Button>
            </div>
        </div>
    ) : <></>;
};

export default DeprecationBanner;
